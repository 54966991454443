<template>
  <div class="pageborder">
    <div class="pageback">
      <envir-page-name style="background-color: #ffffff;" :noBack="true" pageName="公告修改" />
      <div class="pageinpadding">
        <div style="margin-left: 16px;margin-bottom: 16px;">当前公告：{{imageUrl}}</div>
        <el-button
          size="small"
          type="success"
          style="margin-left: 16px;margin-bottom: 16px;"
          @click="newDialog = true">修改公告</el-button>
      </div>
    </div>
    <el-dialog title="修改公告" :visible.sync="newDialog" v-loading="newLoading">
      <div v-for="(user, index2) in userNameList" :key="index2">
        <el-input placeholder="请输入内容" v-model="userInfoObj[user.label]" style="margin:5px;" 
          :show-password="(user.label == 'password' || user.label == 'confirmPassword') ? true : false">
          <template slot="prepend">{{user.name}}</template>
        </el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="newDialog = false">取 消</el-button>
        <el-button type="primary" @click="newUser()">修 改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api';

export default {
  data() {
    return {
      imageUrl: '',
      newLoading: false,
      userInfoObj: {},
      userNameList: [],
      newDialog: false,
    };
  },
  mounted() {
    this.userNameList = [];
    this.userNameList.push({name: '新公告',label: 'title'});
    this.getList();
  },
  methods: {
    async getList(){
      const { result } = await api.post('/searchMatch', {
        searchObj: {
          roomid: '1111111'
        }
      });
      console.log(result);
      this.imageUrl = result.rows[0] ? result.rows[0].title : '';
    },
    async newUser(){
      try{
        this.newLoading = true;
        const { result } = await api.post('/changeMessage', this.userInfoObj);
        console.log(result);
        this.$message.success('新增/修改牌桌成功');
        this.userInfoObj = {};
        this.newDialog = false;
        this.newLoading = false;
        await this.getList();
      }catch(e){
        console.error(e);
        this.newLoading = false;
      }
    }
  }
}
</script>

<style scoped lang="less">
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
